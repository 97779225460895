<template>
  <v-container fluid>
    <TableViewComponent
      title="Table conventions collectives"
      @addItemEvent="onAddElement()"
      addItemLabel="ajouter une convention collective"
      itemLabel="convention collective"
      itemsLabel="conventions collectives"
      :items="collectiveAgreements"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cette convention collective ?"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { CollectiveAgreementService } from "@/service/user/collective_agreement_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";


export default {
  name: "TableCollectiveAgreement",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les données. chaque élément doit avoir un id, ainsi que les fonctions edit, view, delete */
      collectiveAgreements: [],

      /**le service d'accès aux convention collectives */
      collectiveAgreementsService: null,
    };
  },
  methods: {
    /** On part vers la page d'ajout*/
    onAddElement() {
      this.$router.push(routes.collectiveAgreement.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.collectiveAgreements = [];

        let collectiveAgreements = await this.collectiveAgreementsService.getAll();

        collectiveAgreements.forEach((i) => {
          let item = JSON.parse(JSON.stringify(i));
          item.view = () => {
            this.$router.push(
              routes.collectiveAgreement.view.root + "/" + item.id
            );
          };

          //action de modification
          item.edit = () => {
            this.$router.push(
              routes.collectiveAgreement.edit.root + "/" + item.id
            );
          };

          item.delete = async () => {
            try {
              await this.collectiveAgreementsService.delete(item.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression de la convention collective : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.collectiveAgreements.push(item);
        });

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Nom",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Nom complet",
          align: "start",
          sortable: true,
          value: "label",
        },
        {
          text: "Lien",
          align: "start",
          sortable: true,
          value: "link",
          default: false,
        },
        {
          text: "IDCC",
          align: "start",
          sortable: false,
          value: "idcc",
          // width: "80px",
        },
        {
          text: "Numéro",
          align: "start",
          sortable: false,
          value: "number",
          // width: "100px",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "start",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
    
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditUser];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditUser, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadUser];
    },
  },
  mounted() {
    //on instancie les services
    this.collectiveAgreementsService = new CollectiveAgreementService(
      this.$api.getCollectiveAgreementApi()
    );
    this.load();
  },


};
</script>

<style>
</style>